import * as React from "react"
import Helmet from "../../components/helmet/helmet";
import NavBar from "../../components/navbar/navbar";
import "../index.scss"
import Footer from "../../components/footer/footer";
import Table from "../../components/table/table";
import MyPriceListPanels from "./priceListPanels.json";


import "./index.scss"

const PriceList = (props) => {
  const { location } = props;
  return (
    <div className="MainContainer priceListPanels">
      <Helmet />
      <NavBar location={location} />
      <div id="BGImage"></div>
      <div className="Content priceList">
       <Table table={MyPriceListPanels}/>
       <div className="bottomList"> <p>* Ceny usług są uzależnione od wielu czynników takich jak: rodzaj prac, ich wielkość oraz zróżnicowanie, warunki realizacji, zakres zadania, typ obiektu itp. W cenniku podano przybliżone ceny ważniejszych usług.</p>
       <p>* Inne prace nie ujete w cenniku wyceniane będą indywidualnie</p> </div>

      </div>
      
      <Footer />
    </div>
  )
}

export default PriceList;